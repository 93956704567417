/**
 * Generic function for running something when the document is ready
 *
 * See: https://youmightnotneedjquery.com/#ready
 *
 * @param fn
 */
export function onReady(fn: () => void): void {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener(
            'DOMContentLoaded',
            fn,
            {
                once: true,
            }
        );
    }
}
