import { onReady } from '@/utils';

/**
 * Add an 'active' class to top menu nav items if the URL of the page
 * matches the item
 *
 */

onReady(() => {
    // Iterate over the toplevel LIs of the navbar
    //
    // eslint-disable-next-line no-restricted-syntax
    for (const li of document.querySelectorAll<HTMLElement>('nav.navbar li.nav-item')) {
        // For each LI, look at the A tags inside
        //
        // eslint-disable-next-line no-restricted-syntax
        for (const a of li.querySelectorAll<HTMLAnchorElement>('a')) {
            if (a.href === window.location.href) {
                // Found a match, mark the enclosing navbar LI with 'active' class
                // and stop looking
                //
                li.classList.add('active');

                if (a.classList.contains('dropdown-item')) {
                    // If the link is in a dropdown, add an active class there too
                    a.classList.add('active');
                }

                return;
            }
        }
    }
});
